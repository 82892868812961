import React from "react";

import "./index.scss";

export default class About extends React.Component {
  render() {
    const libravatar = this.props.site.libravatar;
    const author = this.props.site.author;
    const description = this.props.site.description;
    return (<article className="calling-card">
        <div className="cc-label">
          <picture>
            <img alt="avatar" src={ `https://seccdn.libravatar.org/avatar/${libravatar}?s=150` } />
          </picture>
          <h1>{author}</h1>
        </div>
        <span className="cc-description">{description}</span>
      </article>)
  }
}

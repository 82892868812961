import { get } from 'lodash'

import React from 'react'
import { graphql, Link } from 'gatsby'
import PostListing from "../components/PostListing"
import About from "../components/About"
// import postQuery from "../queries/posts";
import Layout from "../components/layout"

export default class Index extends React.Component {
  render() {
    const site = get(this, 'props.data.site.siteMetadata')
    const postEdges = get(this, 'props.data.allMarkdownRemark.edges')
      .filter(p => !p.node.fields.draft)
    return (
      <Layout location={this.props.location}>
        <article className='page'>
          <About site={ site } />
          <PostListing postEdges={ postEdges } />
          {
            (postEdges.length > site.postsPerPage) ? (
              <Link className='more-posts-button' to='/posts/2'>more posts</Link>
            ) : null
          }
        </article>
      </Layout>
    );
  }
}

// TODO: limit based on config
// TODO: Draft articles are listed in production, not in posts though
/* eslint no-undef: "off"*/
export const pageQuery =  graphql`
  query IndexPage {
    site {
      siteMetadata {
        title
        author
        description
        libravatar
        postsPerPage
      }
    }
    allMarkdownRemark(
      limit: 7
      filter: { fields: { articleType: { eq: "post" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            draft
          }
          excerpt
          timeToRead
          frontmatter {
            title
            date(formatString: "dddd [the] Do [of] MMMM YYYY")
            tags
          }
        }
      }
    }
  }
`;
